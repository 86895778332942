* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #0076fe;
  --primary-light: #008cff;
  --secondary: #c6c6c6;
  --error: #e30102;
  --text: #053398;
  --text-light: #fff;
  --text-dark: #393939;
  --background: #f1f1f1;
  --background-light: #fff;
  --background-dark: #c6c6c6;
  --border: #000;
  --default-transition: .35s ease;
  --app-header-height: 100px;
  --app-title-height: 60px;
  --app-footer-height: 75px;
  --app-static-height: 235px;
  --page-titlebar-height: 40px;
  --page-navbar-height: 10px;
  --page-toolbar-height: 50px;
}

body {
  background: var(--background);
  color: var(--text);
  font-family: Helvetica;
}

a {
  color: var(--text);
  text-decoration: none;
}

h2 {
  text-align: center;
  padding: .8em;
  font-size: 22px;
}

input {
  border-radius: 0;
}

input[type="button"] {
  -webkit-appearance: none;
  border-radius: 0;
}

select, option {
  border-radius: 0;
}

.align-center {
  text-align: center;
}

.bg-primary {
  background: var(--primary);
}

.bg-primary-light {
  background: var(--primary-light);
}

.color-light {
  color: var(--text-light) !important;
}

.color-dark {
  color: var(--text-dark) !important;
}

.color-primary {
  color: var(--primary) !important;
}

.color-secondary {
  color: var(--secondary) !important;
}

.center {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.right {
  text-align: right;
}

.flex1 {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flex2 {
  -webkit-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.flex3 {
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.flex4 {
  -webkit-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

.flex5 {
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;
}

.flex6 {
  -webkit-flex: 6;
  -ms-flex: 6;
  flex: 6;
}

.flex7 {
  -webkit-flex: 7;
  -ms-flex: 7;
  flex: 7;
}

.flex8 {
  -webkit-flex: 8;
  -ms-flex: 8;
  flex: 8;
}

.flex9 {
  -webkit-flex: 9;
  -ms-flex: 9;
  flex: 9;
}

.flex10 {
  -webkit-flex: 10;
  -ms-flex: 10;
  flex: 10;
}

.text {
  color: var(--text);
  text-transform: uppercase;
  font-family: Ironstrike-BlackItalic;
  font-size: 16px;
}

.text.light {
  letter-spacing: .7px;
  font-family: Ironstrike-BoldItalic;
}

.text:not(.light) {
  letter-spacing: 1.5px;
}

.text.sm {
  font-size: 11px;
}

.text.md {
  font-size: 14px;
}

.text.lg {
  font-size: 20px;
}

.text.xl {
  font-size: 35px;
  line-height: 1.1em;
}

.text.dark {
  color: var(--text-dark);
}

.pad1 {
  padding: 1em;
}

.pad2 {
  padding: 2em;
}

.pad3 {
  padding: 3em;
}

.icon-collapse {
  color: var(--text);
  transition: transform var(--default-transition);
  padding: 10px 5px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.icon-collapse.open {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.underline {
  border-bottom: 1px solid var(--background-dark);
}

.scroll-y {
  border-bottom: 1px solid var(--background-dark);
  overflow: hidden scroll;
}

.fib {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.fi {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.33333em;
  line-height: 1em;
  display: inline-block;
  position: relative;
}

.fi:before {
  content: " ";
}

.fi.fis {
  width: 1em;
}

.fi-nl {
  background-image: url("nl.240773e6.svg");
}

.fi-us {
  background-image: url("us.9ab72f6c.svg");
}
/*# sourceMappingURL=index.373e30ab.css.map */
