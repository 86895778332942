* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary: #0076fe;
  --primary-light: #008cff;
  --secondary: #c6c6c6;

  --error: #e30102;

  --text: #053398;
  --text-light: #ffffff;
  --text-dark: #393939;

  --background: #f1f1f1;
  --background-light: #ffffff;
  --background-dark: #c6c6c6;
  --border: #000000;
  --default-transition: .35s ease;

  --app-header-height: 100px;
  --app-title-height: 60px;
  --app-footer-height: 75px;
  --app-static-height: 235px;

  --page-titlebar-height: 40px;
  --page-navbar-height: 10px;
  --page-toolbar-height: 50px;
}

body {
  background: var(--background);
  color: var(--text);
  font-family: Helvetica;
}

a {
  color: var(--text);
  text-decoration: none;
}

h2 {
  font-size: 22px;
  padding: .8em;
  text-align: center;
}

/** for IOS/safari */
input { border-radius: 0; }
input[type='button'] { border-radius: 0; -webkit-appearance: none; }
select { border-radius: 0; }
option { border-radius: 0; }

.align-center { text-align: center; }

.bg-primary { background: var(--primary); }
.bg-primary-light { background: var(--primary-light); }

.color-light { color: var(--text-light) !important; }
.color-dark { color: var(--text-dark) !important; }
.color-primary { color: var(--primary) !important; }
.color-secondary { color: var(--secondary) !important; }

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.right {
  text-align: right;
}

.flex1 { flex: 1; }
.flex2 { flex: 2; }
.flex3 { flex: 3; }
.flex4 { flex: 4; }
.flex5 { flex: 5; }
.flex6 { flex: 6; }
.flex7 { flex: 7; }
.flex8 { flex: 8; }
.flex9 { flex: 9; }
.flex10 { flex: 10; }

.text {
  color: var(--text);
  font-family: 'Ironstrike-BlackItalic';
  font-size: 16px;
  text-transform: uppercase;
}

.text.light {
  font-family: 'Ironstrike-BoldItalic';
  letter-spacing: 0.7px;
}
.text:not(.light) {
  letter-spacing: 1.5px;
}

.text.sm { font-size: 11px; }
.text.md { font-size: 14px; }
.text.lg { font-size: 20px; }
.text.xl { font-size: 35px; line-height: 1.1em }

.text.dark { color: var(--text-dark); }

.pad1 { padding: 1em; }
.pad2 { padding: 2em; }
.pad3 { padding: 3em; }

.icon-collapse {
  color: var(--text);
  transform: rotate(0);
  transition: transform var(--default-transition);
  padding: 10px 5px;
}
.icon-collapse.open {
  transform: rotate(180deg);
}

.underline { border-bottom: 1px solid var(--background-dark); }
.scroll-y {
  overflow: hidden scroll;
  border-bottom: 1px solid var(--background-dark);
}

.fib {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.fi:before {
  content: '\00a0';
}
.fi.fis {
  width: 1em;
}
.fi-nl {
  background-image: url(img/flags/nl.svg);
}
.fi-us {
  background-image: url(img/flags/us.svg);
}
